import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "provisión-de-servidor-vultrruncloud"
    }}>{`Provisión de servidor Vultr/Runcloud`}</h2>
    <h3 {...{
      "id": "crear-el-servidor-en-vultr"
    }}>{`Crear el servidor en Vultr`}</h3>
    <ul>
      <li parentName="ul">{`Products -> Deploy New Server (+)`}</li>
      <li parentName="ul">{`High frequency, Los Angeles, Ubuntu (latest LTS), `}</li>
      <li parentName="ul">{`Enable Backups`}</li>
    </ul>
    <h3 {...{
      "id": "conectar-el-servidor-a-run-cloud"
    }}>{`Conectar el servidor a Run Cloud`}</h3>
    <p>{`En Run Cloud:`}</p>
    <ol>
      <li parentName="ol">{`Connect New Server -> Vultr -> Connect via IP Address-> Ingresar Server Name / IP`}</li>
      <li parentName="ol">{`Manual Installation -> Follow Instructions`}</li>
      <li parentName="ol">{`System Users -> Crear usuario "ecomerciar"`}</li>
    </ol>
    <p>{`Registar las credenciales.`}</p>
    <h3 {...{
      "id": "creando-webapps"
    }}>{`Creando Webapps`}</h3>
    <h4 {...{
      "id": "para-wordpress"
    }}>{`Para Wordpress`}</h4>
    <ul>
      <li parentName="ul">{`Tipo: Wordpress `}</li>
      <li parentName="ul">{`User (Owner of this Web Application): ecomerciar`}</li>
      <li parentName="ul">{`Web Application Stack: Native NGINx (O HIBRIDO) ?`}</li>
    </ul>
    <p>{`Crear una o varias bases de datos MySQL con uno o varios usuarios y asociarlos`}</p>
    <ul>
      <li parentName="ul">{`Runcloud -> Database -> Create Database, Create User "ecomerciar", Grant User.`}</li>
    </ul>
    <h4 {...{
      "id": "para-app-o-api-php"
    }}>{`Para App o API PHP`}</h4>
    <ul>
      <li parentName="ul">{`Tipo: Custom PHP `}</li>
      <li parentName="ul">{`User (Owner of this Web Application): ecomerciar`}</li>
      <li parentName="ul">{`Web Application Stack: Native NGINx`}</li>
    </ul>
    <p>{`Las siguientes funciones de php vienen desactivadas por defecto por seguridad en runcloud:`}</p>
    <pre><code parentName="pre" {...{}}>{`proc_open, proc_close, fpassthru
`}</code></pre>
    <p>{`Para activarlas, ir a webapp->settings y sacarlas de la seccion "disable_functions"`}</p>
    <h5 {...{
      "id": "ejemplos-de-deploy-script"
    }}>{`Ejemplos de deploy script`}</h5>
    <p>{`Para API Laravel:`}</p>
    <pre><code parentName="pre" {...{}}>{`git merge
composer install
php artisan migrate
`}</code></pre>
    <p>{`Para Webapp Laravel:`}</p>
    <pre><code parentName="pre" {...{}}>{`git merge
composer install
npm install
npm run production
php artisan migrate
`}</code></pre>
    <h4 {...{
      "id": "para-app-o-api-node"
    }}>{`Para App o API Node`}</h4>
    <ul>
      <li parentName="ul">{`Tipo: Custom PHP `}</li>
      <li parentName="ul">{`User (Owner of this Web Application): ecomerciar`}</li>
      <li parentName="ul">{`Web Application Stack: Native NGINx CUSTOM`}</li>
    </ul>
    <p>{`Registar las credenciales:
1. Ecomerciar -> Manage -> Collections
1.1. Clientes/NombreDelCliente
- Comercial
- Desarrollo -> credenciales de usuario ecomerciar
- Developer1 -> credenciales de usuario root
- `}</p>
    <h3 {...{
      "id": "configuraciones-nginx"
    }}>{`Configuraciones nginx`}</h3>
    <p><a parentName="p" {...{
        "href": "https://runcloud.io/docs/guide/cheat-sheet/nginx"
      }}>{`https://runcloud.io/docs/guide/cheat-sheet/nginx`}</a></p>
    <p>{`Cuando se usan rutas de frontend (Vue router, React Router, etc) es necesario configurar Nginx para darle prevalencia a estas rutas para permitir navegacion directa a las mismas:`}</p>
    <p>{`Desde Runcloud se puede agregar esta config desde:`}</p>
    <pre><code parentName="pre" {...{}}>{`{Select Server} -> Web Applications -> {Select App} -> NGINX Config -> Create Config
`}</code></pre>
    <p>{`E ingresando los siguientes valores:`}</p>
    <ul>
      <li parentName="ul">{`Predefined Config (Optional): I want to write my own config`}</li>
      <li parentName="ul">{`Type: location.root`}</li>
      <li parentName="ul">{`Name: use-frontend-router (o mas descriptivo se estan incluyendo otras reglas)`}</li>
      <li parentName="ul">{`Config Content:`}</li>
    </ul>
    <pre><code parentName="pre" {...{}}>{`    location / {
      try_files $uri $uri/ /index.html;
    }
`}</code></pre>
    <p>{`Anteriormente esto lo haciamos de forma manual editando el archivo main.conf de la webapp y agregando el snippet alli.`}</p>
    <h3 {...{
      "id": "habilitar-ssl-en-los-dominios-de-una-webapp"
    }}>{`Habilitar SSL en los dominios de una webapp:`}</h3>
    <p>{`Web Application -> SSL/TLS
Enable HSTS
Let's Encrypt Environment: Live real`}</p>
    <h3 {...{
      "id": "conectar-con-repositorio-en-gitlab"
    }}>{`Conectar con repositorio en gitlab`}</h3>
    <h4 {...{
      "id": "deployment-keys"
    }}>{`Deployment keys:`}</h4>
    <ul>
      <li parentName="ul">{`Runcloud -> Generate deployment key (Deployment keys -> ecomerciar)  `}</li>
      <li parentName="ul">{`Gitlab -> Settings -> `}<strong parentName="li">{`CI/CD`}</strong>{` -> Deployment Keys -> Add key or enable fo this repo. `}</li>
      <li parentName="ul">{`Ó - Gitlab -> Settings -> `}<strong parentName="li">{`Repository`}</strong>{` -> Deployment Keys -> Add key or enable fo this repo. (Lo hann cambiado varias veces de lugar)`}</li>
    </ul>
    <h4 {...{
      "id": "conectar-repo"
    }}>{`Conectar Repo:`}</h4>
    <p>{`Runcloud -> Web Application -> Git -> Gitlab`}</p>
    <h4 {...{
      "id": "webhook"
    }}>{`Webhook:`}</h4>
    <ul>
      <li parentName="ul">{`Runcloud -> Web Application -> Git -> Webhook URL -> copiar`}</li>
      <li parentName="ul">{`Gitlab-> Settings -> CI/CD -> Add Webhook`}</li>
    </ul>
    <h2 {...{
      "id": "provisión-de-servidor-nginxnodemongodb"
    }}>{`Provisión de servidor Nginx+Node+Mongodb`}</h2>
    <p>{`Apenas queda creado el server, loguearse como root, crear service user e instalar los componentes del stack`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ apt update

# Create service system user, enter new password
$ adduser conexa
# opcionalmente agregar a conexa al sudoers file

#Install nginx
$ apt install nginx
# opcionalmente: configurar firewall 

# Install node, npm, pm2
$ apt install nodejs
$ apt install npm
$ npm i -g pm2

# Install Mongo
$ curl -fsSL https://www.mongodb.org/static/pgp/server-4.4.asc | sudo apt-key add -
$ echo "deb [ arch=amd64,arm64 ] https://repo.mongodb.org/apt/ubuntu focal/mongodb-org/4.4 multiverse" | sudo tee /etc/apt/sources.list.d/mongodb-org-4.4.list
$ sudo apt update
$ apt install mongodb-org
$ systemctl start mongod
$ systemctl status mongod
$ sudo systemctl enable mongod

# Install certbot
$ snap install certbot --classic
`}</code></pre>
    <p>{`Guardar la contraseña del usuario "conexa"`}</p>
    <p>{`Loguearse como el usuario "conexa" , para generar las deploy keys y clonar el/los repositorios de la/s apps o apis que se serviran:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# Generate keys
$ ssh-keygen
# Show generated pub key, copy to gitlab
$ cat .ssh/id_rsa.pub
# Clone inside /home/conexa using ssh git protocol
$ git clone git@gitlab.com:...
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://docs.gitlab.com/ee/ssh/README.html#generate-an-ssh-key-pair"
      }}>{`https://docs.gitlab.com/ee/ssh/README.html#generate-an-ssh-key-pair`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://docs.gitlab.com/ee/user/project/deploy_keys/index.html"
      }}>{`https://docs.gitlab.com/ee/user/project/deploy_keys/index.html`}</a></p>
    <p>{`Seguir los pasos particulares para instalar, buildear y levantar cada App o API.`}</p>
    <p>{`Apuntar el/los dominios a la ip del server mediante un registro tipo A.`}</p>
    <p>{`Desde el usuario root, configurar en nginx los sitios/dominios `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ sudo /etc/nginx/sites-enabled/default
`}</code></pre>
    <h3 {...{
      "id": "modelos-de-config-nginx"
    }}>{`Modelos de config nginx:`}</h3>
    <h4 {...{
      "id": "app-ó-api-node-sola"
    }}>{`App ó API Node sola`}</h4>
    <pre><code parentName="pre" {...{}}>{`server {
    server_name example.com;
    listen 80;
    location / {
        proxy_pass http://localhost:8080;
    }
}
`}</code></pre>
    <h4 {...{
      "id": "app-node-con-api-y-frontend-en-un-mismo-repo"
    }}>{`App node con API y Frontend en un mismo repo`}</h4>
    <pre><code parentName="pre" {...{}}>{`# API:
server {
    server_name api.example.com;
    listen 80;
    location / {
        proxy_pass http://localhost:8080;
    }
}
# Frontend:
server {
    server_name front.example.com;
    listen 80;
    root /home/conexa/example/frontend/dist;
    location / {
        try_files $uri $uri/ /index.html;
    }
}
`}</code></pre>
    <h4 {...{
      "id": "repos-separados-para-api-y-frontend"
    }}>{`Repos separados para API y Frontend`}</h4>
    <pre><code parentName="pre" {...{}}>{`# API:
server {
    server_name api.example.com;
    listen 80;
    location / {
        proxy_pass http://localhost:8080;
    }
}
# Frontend:
server {
    server_name front.example.com;
    listen 80;
    root /home/conexa/example-frontend/dist;
    location / {
        try_files $uri $uri/ /index.html;
    }
}
`}</code></pre>
    <p>{`Generar los certificados ssl usando certbot`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`$ certbot -d example.com
`}</code></pre>
    <h3 {...{
      "id": "dominio-en-cloudflare"
    }}>{`Dominio en Cloudflare`}</h3>
    <p>{`Se crea una nueva cuenta en cloudflare con un email como:
nombredelcliente@ecomerciar.com, este mail no tiene que existir realmente ya qeu los mensajes son recibidos por la cuenta catch-all.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      